<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <b-row>
                    <b-col>
                        <h4>Added items</h4>
                        <basic-table :columns="addedItemColumns" :data="config.items" v-slot="props">
                            <div v-if="props.column.displayType === 1" class="d-flex justify-content-center" style="min-width: 100px">
                                <img :src="'/api/management/v1/image/' + props.row.image" style="max-width: 100%; max-height: 100px;" class="rounded-lg" alt="Image"/>
                            </div>
                            <div v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">

                                <b-button v-if="props.row.originalIndex > 0" variant="primary" @click="moveUp(props.row.originalIndex)" class="mr-1">
                                    <feather-icon
                                        icon="ArrowUpIcon"
                                    />
                                </b-button>

                                <b-button v-if="props.row.originalIndex < config.items.length - 1" variant="primary" @click="moveDown(props.row.originalIndex)" class="mr-1">
                                    <feather-icon
                                        icon="ArrowDownIcon"
                                    />
                                </b-button>

                                <b-button variant="danger" @click="removeItem(props.row)">
                                    <feather-icon
                                        icon="Trash2Icon"
                                    />
                                </b-button>
                            </div>
                            <span v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                                {{ getItemName(props.row) }}
                            </span>
                            <span v-else class="d-flex justify-content-center">
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </basic-table>
                    </b-col>

                    <b-col>
                        <h4>Addable items</h4>
                        <basic-table :columns="addableItemColumns" :data="addableItems" v-slot="props">
                            <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                                <b-button variant="success" @click="openAddModal(props.row)">
                                    <feather-icon
                                        icon="PlusIcon"
                                    />
                                </b-button>
                            </div>
                            <span v-else class="d-flex justify-content-center">
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </basic-table>
                    </b-col>
                </b-row>
                <hr/>

                <b-button variant="success" @click="saveConfig()">
                    <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save</span>
                </b-button>
            </b-card>
        </b-overlay>

        <b-modal title="Add item" v-model="addModalActive" no-close-on-backdrop hide-footer>
            <template #default>
                <b-form-group>
                    <label>Name:</label>
                    <b-form-input
                        type="text"
                        disabled=""
                        placeholder="Name"
                        v-model="addObject.name"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Image:</label>
                    <b-form-file
                        id="imageUpload"
                        v-model="imageFile"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                    />
                </b-form-group>
            </template>
        </b-modal>

    </div>
</template>
<script>

    import {BButton, BCard, BCol, BFormFile, BFormGroup, BFormInput, BModal, BOverlay, BRow} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'

    export default {
        components: {
            BOverlay,
            BCard,
            BButton,
            BasicTable,
            BRow,
            BCol,
            BModal,
            BFormInput,
            BFormGroup,
            BFormFile
        },
        data() {
            return {
                dataLoaded: false,
                config: {
                    items: []
                },

                addModalActive: false,
                addObject: {},
                imageFile: null,

                staticItems: [
                    {
                        id: '',
                        type: 1,
                        name: 'TV'
                    },
                    {
                        id: '',
                        type: 2,
                        name: 'Bill'
                    }
                ],

                baseItems: [],

                addedItemColumns: [
                    {
                        label: 'Image',
                        displayType: 1,
                        field: 'image',
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 3,
                        field: 'name',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 2,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addableItemColumns: [
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]
            }
        },
        methods: {
            openAddModal(object) {
                this.addObject = JSON.parse(JSON.stringify(object))
                this.addModalActive = true
            },
            loadData() {

                this.dataLoaded = false
                const thisIns = this

                const loadPromise = this.$http.get('/api/management/v1/config/base/home_screen')
                loadPromise.then(function(response) {
                    thisIns.config = response.data.config
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                const baseItemsPromise = this.$http.get('/api/management/v1/base_item/')
                baseItemsPromise.then(function(response) {
                    thisIns.baseItems = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise, baseItemsPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            saveConfig() {

                this.dataLoaded = false
                const thisIns = this

                const savePromise = this.$http.put('/api/management/v1/config/base/home_screen', {
                    config: this.config
                })
                savePromise.then(function() {
                    thisIns.$printSuccess('Config saved')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            addItem() {
                this.config.items.push({
                    id: this.addObject.id,
                    type: this.addObject.type,
                    image: this.addObject.image
                })
                this.addObject = {}
                this.addModalActive = false
            },
            removeItem(item) {
                this.config.items.splice(this.config.items.findIndex(configItem => configItem.id === item.id && configItem.type === item.type), 1)
            },
            moveUp(index) {
                const tmp = this.config.items[index - 1]
                this.$set(this.config.items, index - 1, this.config.items[index])
                this.$set(this.config.items, index, tmp)
            },
            moveDown(index) {
                const tmp = this.config.items[index + 1]
                this.$set(this.config.items, index + 1, this.config.items[index])
                this.$set(this.config.items, index, tmp)
            },
            getItemName(item) {
                for (let x = 0; x < this.allItems.length; x++) if (this.allItems[x].id === item.id && this.allItems[x].type === item.type) return this.allItems[x].name
                return 'Unknown'
            }
        },
        computed: {
            addableItems() {
                return this.allItems.filter(addableItem => !this.config.items.some(addedItem => (addedItem.id === addableItem.id && addedItem.type === addableItem.type)))
            },
            allItems() {

                const items = JSON.parse(JSON.stringify(this.staticItems))

                for (let x = 0; x < this.baseItems.length; x++) {
                    items.push({
                        id: this.baseItems[x].id,
                        type: 0,
                        name: this.baseItems[x].name
                    })
                }

                return items
            }
        },
        watch: {
            imageFile() {
                if (this.imageFile === null) return

                const thisIns = this
                const formData = new FormData()
                formData.append('file', this.imageFile)
                this.$http.post('/api/management/v1/image/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(function(response) {
                    thisIns.$set(thisIns.addObject, 'image', response.data)
                    thisIns.addItem()
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.imageFile = null
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>